<template>
    <ais-instant-search-ssr class="gap-x-8 lg:flex">
        <ais-configure :hits-per-page.camel="9" :filters="defaultFilters" />

        <div class="flex-none">
            <div
                class="btn-primary flex items-center justify-center gap-x-2 lg:hidden lg:flex-none"
                @click="mobileMenuOpen = true"
            >
                Filter <Icon name="bars-filter-solid" class="text-xl" />
            </div>
            <transition name="slide-up">
                <div
                    v-show="mobileMenuOpen"
                    class="fixed inset-x-0 top-0 z-[999999] h-screen overflow-y-scroll bg-white px-4 py-4 lg:hidden lg:flex-none"
                >
                    <div class="mb-4 flex justify-between">
                        <h3 class="text-xl">Product Filter</h3>
                        <Icon
                            name="xmark-solid"
                            class="text-xl"
                            @click="mobileMenuOpen = false"
                        />
                    </div>
                    <FiltersLists
                        :type="type"
                        :industry="industry"
                        :mobile-menu-open="mobileMenuOpen"
                    />
                    <div class="mt-4 flex">
                        <div
                            class="btn-primary w-full text-center"
                            @click="mobileMenuOpen = false"
                        >
                            <ais-stats>
                                <template #default="{ nbHits }">
                                    <div class="text-white">
                                        View {{ nbHits }}
                                        <span v-if="nbHits > 1">results</span
                                        ><span v-else>result</span>
                                    </div>
                                </template>
                            </ais-stats>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
        <aside class="flex-none lg:top-32 lg:w-1/4 lg:self-start">
            <div class="hidden lg:block">
                <FiltersLists :type="type" :industry="industry" />
            </div>

            <div
                v-if="$slots['product-filter-cta']"
                class="mt-8 hidden lg:block"
            >
                <slot name="product-filter-cta" />
            </div>
        </aside>
        <div class="mt-8 flex-1 lg:mt-0">
            <div class="w-full lg:min-h-[44px]">
                <ais-current-refinements class="mb-4 flex gap-2">
                    <template #default="{ items, createURL }">
                        <template v-for="item in items" :key="item.attribute">
                            <div
                                v-for="refinement in item.refinements"
                                :key="
                                    [
                                        refinement.attribute,
                                        refinement.type,
                                        refinement.value,
                                        refinement.operator,
                                    ].join(':')
                                "
                                class="rounded-md bg-white shadow-md hover:bg-gray-100"
                            >
                                <a
                                    :href="createURL(refinement)"
                                    class="flex items-center gap-2 px-5 py-3 text-xs uppercase text-grind no-underline"
                                    @click.prevent="item.refine(refinement)"
                                >
                                    {{ refinement.label }}
                                    <Icon
                                        name="filter-x"
                                        class="text-xs text-grind"
                                    />
                                </a>
                            </div>
                        </template>
                    </template>
                </ais-current-refinements>
            </div>
            <ais-infinite-hits>
                <template #default="{ items, isLastPage }">
                    <div
                        class="mt-4 grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-3"
                    >
                        <ProductFilterCard
                            v-for="item in items"
                            :key="item.objectID"
                            :product="item"
                            :industry="industry"
                        />
                    </div>
                    <div v-if="!isLastPage" class="mt-10 text-center">
                        <button class="btn-primary" @click="showMore()">
                            Show More
                        </button>
                    </div>
                </template>
            </ais-infinite-hits>
            <div class="invisible">
                <!-- This exists solely for Google to crawl, hence why it's invisible to the user: -->
                <ais-pagination></ais-pagination>
            </div>

            <div
                v-if="$slots['product-filter-cta-mobile']"
                class="mb-12 mt-8 md:mb-20 lg:hidden"
            >
                <slot name="product-filter-cta-mobile" />
            </div>
        </div>
    </ais-instant-search-ssr>
</template>

<script lang="ts" setup>
import {
    AisClearRefinements,
    AisConfigure,
    AisCurrentRefinements,
    AisInfiniteHits,
    AisInstantSearchSsr,
    AisPagination,
    AisRefinementList,
    AisStats,
    createServerRootMixin,
} from 'vue-instantsearch/vue3/es';
import { history as historyRouter } from 'instantsearch.js/es/lib/routers';
import { ref } from 'vue';
import { renderToString } from '@vue/server-renderer';
import ProductFilterCard from '~/components/page-building/product-filter/components/ProductFilterCard.vue';
import FiltersLists from '~/components/page-building/product-filter/components/FiltersLists.vue';
import { useAlgoliaProductStructuredData } from '~/composables/useStructuredData';
import { useRuntimeConfig } from '#app/nuxt';

const props = defineProps<{
    type: string;
    industry?: string;
}>();

const algoliaProductIndex = computed<string>(() => {
    return useRuntimeConfig().public.algoliaProductIndex;
});

const algoliaIndex = computed<string>(() => {
    if (props.type && props.type !== 'industry') {
        return algoliaProductIndex.value + '_' + props.type;
    }
    if (props.industry) {
        return algoliaProductIndex.value + '_' + props.industry;
    }
    return algoliaProductIndex.value;
});

const defaultFilters = computed<string>(() => {
    if (props.type && props.type !== 'view-all' && props.type !== 'industry') {
        switch (props.type) {
            case 'design-lab':
                return 'category:"Roastar Design Lab"';

            default:
                return `category:${props.type}`;
        }
    }
    if (props.industry) {
        return 'industry:' + props.industry;
    }
    return '';
});

const mobileMenuOpen = ref(false);
const indexName = algoliaIndex.value;
const route = useRuntimeConfig().public.baseUrl + useRoute().path;
const includePageInRoute = ref(true);
let initialPage = 0;

const decodeIndividualFilterChoices = (choices: any) => {
    for (let i = 0; i < choices.length; i++) {
        choices[i] = decodeURIComponent(choices[i]);
    }
    return choices;
};

const defaultRefinementList: any = {};
let searchSplit = useRequestURL().search.replace('?', '').split('&');
for (let i = 0; i < searchSplit.length; i++) {
    let searchParam = searchSplit[i].split('=');
    
    if (searchParam[0] == 'page') {
        initialPage = parseInt(searchParam[1]);
    } else if (searchParam[0] == 'productType') {
        defaultRefinementList.productType = decodeIndividualFilterChoices(decodeURI(searchParam[1]).replace(/\+/g, ' ').replace(/\%2F/g, '/').split('|'));
    } else if (searchParam[0] == 'material') {
        defaultRefinementList.material = decodeIndividualFilterChoices(decodeURI(searchParam[1]).replace(/\+/g, ' ').split('|'));
    } else if (searchParam[0] == 'ecoFriendly') {
        defaultRefinementList.ecoFriendly = decodeIndividualFilterChoices(decodeURI(searchParam[1]).replace(/\+/g, ' ').replace(/\%2F/g, '/').split('|'));
    } else if (searchParam[0] == 'capacity') {
        defaultRefinementList.capacity = decodeIndividualFilterChoices(decodeURI(searchParam[1]).replace(/\+/g, ' ').replace(/\%2F/g, '/').split('|'));
    } else if (searchParam[0] == 'minimumOrderQuantity') {
        defaultRefinementList.minimumOrderQuantity = decodeIndividualFilterChoices(decodeURI(searchParam[1]).replace(/\+/g, ' ').replace(/\%2F/g, '/').split('|'));
    } else if (searchParam[0] == 'availability') {
        defaultRefinementList.category = decodeIndividualFilterChoices(decodeURI(searchParam[1]).replace(/\+/g, ' ').replace(/\%2F/g, '/').split('|'));
    }
}

const algolia = useAlgoliaRef();
const serverRootMixin = ref(
    createServerRootMixin({
        searchClient: algolia,
        routing: {
            router: historyRouter({
                getLocation() {
                    if (typeof window === 'undefined') {
                        return new URL(route);
                    }
                    return window.location;
                },
                createURL({ qsModule, routeState, location }) {
                    const baseUrl = useRuntimeConfig().public.baseUrl;
                    const categoryPath = useRoute().path;
                    const queryParameters = {};
                    let page = 0;

                    if (routeState[indexName]) {
                        if (routeState[indexName].refinementList?.availability) {
                            queryParameters.availability =routeState[indexName].refinementList?.availability?.join('|');
                        }
                        if (routeState[indexName].refinementList?.productType) {
                            queryParameters.productType = routeState[indexName].refinementList?.productType?.join('|');
                        }
                        if (routeState[indexName].refinementList?.material) {
                            queryParameters.material = routeState[indexName].refinementList?.material?.join('|');
                        }
                        if (routeState[indexName].refinementList?.ecoFriendly) {
                            queryParameters.ecoFriendly = routeState[indexName].refinementList?.ecoFriendly?.join('|');
                        }
                        if (routeState[indexName].refinementList?.capacity) {
                            queryParameters.capacity = routeState[indexName].refinementList?.capacity?.join('|');
                        }
                        if (routeState[indexName].refinementList?.minimumOrderQuantity) {
                            queryParameters.minimumOrderQuantity = routeState[indexName].refinementList?.minimumOrderQuantity?.join('|');
                        }
                        if (routeState[indexName].refinementList?.category) {
                            queryParameters.availability = routeState[indexName].refinementList?.category?.join('|');
                        }
                        if (routeState[indexName].page && routeState[indexName].page > 1 && includePageInRoute.value) {
                            queryParameters.page = (routeState[indexName].page ?? 1) - 1;
                        } else if (initialPage > 0 && includePageInRoute.value) {
                            queryParameters.page = initialPage;
                        }
                    }

                    const queryString = qsModule.stringify(queryParameters, {
                        addQueryPrefix: false,
                        arrayFormat: 'repeat',
                    });

                    let route = baseUrl + categoryPath + (queryString != '' ? '?' + queryString : '');
                    if (!page && queryString === '') {
                        route = baseUrl + categoryPath;
                    }

                    return route;
                },
            }),
        },
        indexName,
        initialUiState: {
            [algoliaIndex.value]: {
                configure: {
                    hitsPerPage: 9,
                    page: initialPage,
                    filters: defaultFilters.value
                },
                refinementList: defaultRefinementList,
            },
        },
    }),
);

function showMore() {
    includePageInRoute.value = false
    instantsearch.renderState[indexName].infiniteHits.showMore();
}

const { instantsearch } = serverRootMixin.value.data();
provide('$_ais_ssrInstantSearchInstance', instantsearch);

const { data: algoliaState } = await useAsyncData('algolia-state', async () => {
    if (process.server) {
        const nuxtApp = useNuxtApp();
        nuxtApp.$algolia.transporter.requester = (
            await import('@algolia/requester-node-http').then(
                (lib) => lib.default || lib,
            )
        ).createNodeHttpRequester();
    }
    return instantsearch.findResultsState({
        // IMPORTANT: a component with access to `this.instantsearch` to be used by the createServerRootMixin code
        component: {
            $options: {
                components: {
                    AisInstantSearchSsr,
                    AisStats,
                    AisConfigure,
                    AisRefinementList,
                    AisClearRefinements,
                    AisCurrentRefinements,
                    AisInfiniteHits,
                },
                data() {
                    return { instantsearch };
                },
                provide: { $_ais_ssrInstantSearchInstance: instantsearch },
                render() {
                    return h(AisInstantSearchSsr, null, () => [
                        // Include any vue-instantsearch components that you use including each refinement attribute
                        h(AisInfiniteHits),
                        h(AisStats),
                        h(AisClearRefinements),
                        h(AisCurrentRefinements),
                        h(AisConfigure),
                        h(AisRefinementList, {
                            attribute: 'category',
                            operator: 'or',
                        }),
                        h(AisRefinementList, {
                            attribute: 'industry',
                            operator: 'or',
                        }),
                        h(AisRefinementList, {
                            attribute: 'productType',
                            operator: 'or',
                        }),
                        h(AisRefinementList, {
                            attribute: 'material',
                            operator: 'or',
                        }),
                        h(AisRefinementList, {
                            attribute: 'ecoFriendly',
                            operator: 'or',
                        }),
                        h(AisRefinementList, {
                            attribute: 'capacity',
                            operator: 'or',
                        }),
                        h(AisRefinementList, {
                            attribute: 'minimumOrderQuantity',
                            operator: 'or',
                        }),
                    ]);
                },
            },
        },
        renderToString,
    });
});

for (const hit in algoliaState.value[indexName].results[0].hits) {
    const structuredData = useAlgoliaProductStructuredData(
        algoliaState.value[indexName].results[0].hits[hit],
    );
    useServerHead(() => ({
        script: structuredData,
    }));
}

onBeforeMount(() => {
    instantsearch.hydrate(algoliaState.value);
});
</script>

<style scoped>
.slide-up-enter-active,
.slide-up-leave-active {
    transition: 0.5s ease;
    top: 0;
}

.slide-up-enter-from,
.slide-up-leave-to {
    top: 100%;
}
</style>
